import React from "react"
import { useWindowSize, useWindowScroll } from "react-use"
import { useIsClient } from "./use-is-client"
export const useScrollProgress = (rootRef, normalize = true) => {
  const scroll = useWindowScroll()
  const windowSize = useWindowSize()
  const isClient = useIsClient()
  const [offset, setOffset] = React.useState({ top: 0, bottom: 0 })
  const updateOffset = React.useCallback(() => {
    const top = rootRef.current.offsetTop
    const bottom = top + rootRef.current.offsetHeight
    setOffset({ top, bottom })
  }, [rootRef])
  const progress = React.useMemo(() => {
    if (scroll.y > offset.bottom) return 1
    if (scroll.y < offset.top) return 0
    const height = offset.bottom - offset.top
    const relativeY = normalize ? (scroll.y - offset.top) / height : 1
    const normalizedY = scroll.y + windowSize.height * relativeY - offset.top
    return normalizedY / height
  }, [windowSize, offset, scroll])
  React.useEffect(() => {
    if (rootRef.current) updateOffset()
  }, [isClient, windowSize])
  return progress
}
